import { Box } from '@mui/material'
import MyCard from 'components/shared/Card/Card'
import theme from 'components/utils/theme'
import { useMemo } from 'react'
import PieChart from '../Device/PieChart'
import CustomLegend from './CustomLegend'
import GraphTitle from './GraphTitle'
import { processChartData } from './helper'
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import styles from '../../shared/Tables/MyTable/myTable.module.scss';
import { useDispatch } from 'react-redux'
import { uiActions } from '../../../redux/slice/ui.slice'
import { useNavigate } from 'react-router-dom'
import { alertsActions } from '../../../redux/slice/alerts.slice'

const ThreatComparison = ({ alerts }) => {
    const arr = [
        {
            label: "High Alerts",
            color: theme.palette.error.main
        },
        {
            label: "Medium Alerts",
            color: theme.palette.warning.main
        },
        {
            label: "Low Alerts",
            color: theme.palette.success.main
        },
    ]
    const threats = useMemo(() => {
        return processChartData(alerts)
    }, [alerts]);


    const graphColors = [
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.success.main,
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClickChart = (data) => {
        console.log("data", data);
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        switch (data) {
            case 'a':
                dispatch(alertsActions.actions.setSelectedLevel("High"))
                break;
            case 'b':
                dispatch(alertsActions.actions.setSelectedLevel("Medium"))
                break;
            case 'c':
                dispatch(alertsActions.actions.setSelectedLevel("Low"))
                break;
            default:
            //
        }
        navigate("/dashboard");

    }
    return (
        <MyCard>
            <GraphTitle showTimeMenu={false} title={'Alerts Comparison'} />
            {threats?.total !== 0 ?
                <>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <PieChart
                            chartData={threats?.chartData}
                            width={400}
                            height={230}
                            graphColors={graphColors}
                            totalValue={threats?.total}
                            radius={90}
                            left={"43.5%"}
                            top={'80px'}
                            showLabels={true}
                            totalText={"Total"}
                            showPercentage={false}
                            handleClick={handleClickChart}
                        />
                    </Box>
                    <CustomLegend arr={arr} />
                </>

                :
                <>
                    <Box className={styles.noData}><ContentPasteOffIcon /> No Threats Detected</Box>
                </>
            }
        </MyCard>
    )
}

export default ThreatComparison
