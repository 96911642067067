import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BarsIcon from "static/svgs/Bars";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import { getDevicesSocket } from "../../../../redux/saga/socket";
import { addDeviceToGroupService } from "../../../../redux/services/group.service";
import { authActions } from "../../../../redux/slice/auth.slice";
import { devicesActions } from "../../../../redux/slice/devices.slice";
import { GET_DEVICES } from "../../../../redux/types/devices.types";
import { customTableStyles, getBadge } from "../../../shared/Tables/helper";
import styles from "./Devices.module.scss";

const columns = [
  { field: "deviceName", headerName: "Device Name", flex: 1 },
  {
    field: "systemDetails",
    headerName: "OS",
    flex: 1,
    valueGetter: (params) => {
      console.log("params?.row", params?.row)
      return params?.os || "Invalid OS";
    },
  },
  {
    field: "ip",
    headerName: "IP",
    flex: 1,
    renderCell: (params) => {
      return params?.row?.systemDetails?.ip
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => getBadge(params?.row?.status),
  },
  {
    field: "lastActive",
    headerName: "Last Active",
    flex: 1.3,
    valueGetter: (params) => {
      const date = new Date(params);
      return isValid(date)
        ? format(date, "d MMMM, yyyy h:mmaaa")
        : "Invalid date";
    },
  },
];

const Devices = ({ groups, refreshGroups }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const devices = useSelector(devicesActions.selectors.getDevices);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpen = () => {
    if (selectedDevices.length === 0) {
      toast.error("Please choose device first")
      return
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGroup("");
  };

  useEffect(() => {
    getDevicesSocket({
      orgId: process.env.REACT_APP_ORG_ID,
      addedBy: currentUser?._id,
    });
  }, [currentUser?._id]);

  useEffect(() => {
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch({ type: GET_DEVICES, payload });
  }, [dispatch]);

  const [pageSize, setPageSize] = useState(10);

  const handleAddDeviceToGroup = async () => {
    try {
      await addDeviceToGroupService(selectedGroup, selectedDevices);
      handleClose();
      refreshGroups();
    } catch (error) {
      console.error("Failed to add devices to group", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredDevices = devices.filter(
    (device) =>
      (device?.deviceId?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        device?.assetId?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        (device?.systemDetails?.os || "")?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        (device?.createdAt ? format(new Date(device.createdAt), "d MMMM, yyyy h:mmaaa") : "")
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        (device.lastActive ? format(new Date(device.lastActive), "d MMMM, yyyy h:mmaaa") : "")
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())) && (device.status === "Connected" || device.status === "Disconnected")
  );

  console.log("filteredDevices", filteredDevices)
  return (
    <Box className={styles.container}>
      <Box className={styles.headerContainer}>
        <Typography variant="h6" className={styles.header}>
          Devices <span className={styles.badge}>{devices?.length || 0}</span>
        </Typography>
        <Typography className={styles.subheader}>
          A Device is an Object in BDATA that Defines Permissions.
        </Typography>
        <Box className={styles.controls}>
          <Box className={styles.iconButtons}>
            <IconButton className={styles.iconBtn} onClick={() => handleDownloadCsv(devices, columns, true, false)}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={styles.iconBtn} onClick={() => handlePrintCsv(devices, columns, true, false)}>
              <PrintIcon />
            </IconButton>
            <IconButton className={styles.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.createButton}
            onClick={handleOpen}
          // disabled={selectedDevices.length === 0}
          >
            Add Devices to Group
            <Add className={styles.icon} />
          </Button>
        </Box>
      </Box>
      <Box className={styles.tableControls}>
        <TextField
          variant="outlined"
          placeholder="Search for devices"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
      </Box>
      <Box sx={{ width: "100%", height: "450px" }}>
        {console.log("filteredDevices", filteredDevices)}
        <DataGrid
          rows={filteredDevices}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          sx={customTableStyles}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedDevices(newRowSelectionModel);
          }}
          rowSelectionModel={selectedDevices}
          disableColumnMenu
          getRowId={(row) => row?._id}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Group</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Group</InputLabel>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              label="Group"
            >
              {Object.keys(groups).map((groupType) =>
                groups[groupType].map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddDeviceToGroup}
            color="primary"
            disabled={!selectedGroup}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Devices;
