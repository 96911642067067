import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { DeviceStatus } from "components/utils/enums";
import theme from "components/utils/theme";
import cytoscape from "cytoscape";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import IPIcon from "static/svgs/IP";
import { ArrowExport, DeviceTopology, NetworkTopology } from "static/svgs/shared";
import text from '../../../../shared/css/text.module.scss';
import styles from "./TopologyDetails.module.scss";
import { convertToElements, getIconForNode, truncateLabel } from "./helper";
import { Fullscreen } from "@mui/icons-material";

const MultiTopology = ({ deviceNetworks, handleFullScreen, isFullScreen, xOffset, yOffset }) => {
    const containerRef = useRef(null);
    const cyRef = useRef(null);
    const [tooltipData, setTooltipData] = useState({
        open: false,
        device: {},
        x: 0,
        y: 0,
    });

    const [forceRender, setForeceRender] = useState(0);

    useEffect(() => {
        setForeceRender((prevVal) => prevVal + 1)
    }, [isFullScreen])

    const handleClickFullScreen = () => {
        handleFullScreen();
        setTooltipData({
            open: false,
            device: {},
            x: 0,
            y: 0,
        })
    }


    const handleMouseOver = useCallback((event) => {
        const node = event.target;
        const position = node.renderedPosition();
        if (!tooltipData?.open) {
            setTooltipData({
                open: true,
                device: node.data('device'),
                x: position.x,
                y: position.y,
            });
        }
    }, [tooltipData?.open]);

    const handleMouseOut = () => {
        setTooltipData((prevTooltipData) => ({
            ...prevTooltipData,
            open: false,
        }));
    }

    const memoizedElements = useMemo(() => {
        if (Array.isArray(deviceNetworks)) {
            return deviceNetworks.flatMap(network => convertToElements(network));
        }
        return [];
    }, [deviceNetworks]);

    useEffect(() => {
        let cy = cyRef.current;

        if (!cy) {
            // Initialize Cytoscape instance if it doesn't exist
            cy = cytoscape({
                container: containerRef.current,
                elements: memoizedElements,
                style: [
                    {
                        selector: "node",
                        style: {
                            "background-opacity": 0,
                            "background-image": (ele) => `data:image/svg+xml;utf8,${encodeURIComponent(getIconForNode(ele))}`,
                            "background-width": "50px",
                            "background-height": "50px",
                            "background-fit": "contain",
                            label: (ele) => truncateLabel(ele.data('label')),
                            "text-valign": "top",
                            "text-halign": "center",
                            "text-margin-y": -10,
                            "text-outline-width": 0,
                            "text-outline-color": "#fff",
                            color: "#000",
                            "font-size": "10px",
                        },
                    },
                    {
                        selector: "edge",
                        style: {
                            width: 1.5,
                            "line-color": "#000",
                            "target-arrow-color": "#000",
                            "target-arrow-shape": "triangle",
                            "curve-style": "bezier",
                        },
                    },
                ],
                layout: {
                    name: "breadthfirst",
                    padding: 10,
                },
                zoom: memoizedElements.length > 50 ? 0.5 : 1,
                minZoom: 0.1,
                maxZoom: 2,
            });

            cy.on('mouseover', 'node', handleMouseOver);

            cy.on('mouseout', 'node', handleMouseOut);

            cyRef.current = cy;
        } else {
            const previousZoom = cy.zoom();
            const previousPan = cy.pan();

            cy.json({ elements: memoizedElements });

            cy.zoom(previousZoom);
            cy.pan(previousPan);
        }

        cy.fit();

        return () => {
            if (cy) {
                cy.destroy();
                cyRef.current = null;
            }
        };
    }, [handleMouseOver, memoizedElements, tooltipData, forceRender]);

    const navigate = useNavigate();

    const handleClickDetails = () => {
        navigate(`/single-dashboard/${tooltipData?.device?._id}`);
    }

    return (
        <Box className={!isFullScreen ? styles.container : styles.containerFullScreen} padding={2}>
            {!isFullScreen &&
                <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography className={text.heading2}>List Topology Details</Typography>
                    <IconButton onClick={handleClickFullScreen} >
                        <Fullscreen />
                    </IconButton>
                </Grid>
            }
            <Grid item xs={12}>
                <div className={styles.topology} style={{ height: !isFullScreen ? '326px' : '100vh' }} ref={containerRef} />

            </Grid>
            {tooltipData?.open &&
                <Tooltip
                    open={tooltipData?.open}
                    className={styles.tooltipTopology}
                    placement="bottom"
                    style={{
                        display: tooltipData.x ? 'block' : 'none',
                        left: tooltipData.x + xOffset,
                        top: tooltipData.y + yOffset,
                    }}
                >
                    <Box className={styles.topologyCard} >
                        <Box className={styles.topologyItem}>
                            <Typography variant="p"><strong>BTA-US08</strong></Typography>
                            <Button endIcon={<ArrowExport fill={tooltipData?.device?.status === DeviceStatus.registered ? theme.palette.gray.main : 'white'} />} disabled={tooltipData?.device?.status === DeviceStatus.registered} onClick={handleClickDetails} variant="contained" className={styles.topologyButton} color="primary">Details</Button>
                        </Box>
                        <Box className={styles.topologyItem}>
                            <Box display={'flex'} alignItems={'center'} gap={'3px'}>
                                <NetworkTopology />
                                <Typography className={styles.topologyHeading} variant="p">Network ID </Typography>
                            </Box>
                            <Typography variant="p" className={styles.topologyText}> {tooltipData?.device?.networkId}</Typography>
                        </Box>
                        <Box className={styles.topologyItem}>
                            <Box display={'flex'} alignItems={'center'} gap={'3px'}>
                                <DeviceTopology />
                                <Typography className={styles.topologyHeading} variant="p">Device ID </Typography>
                            </Box>
                            <Typography variant="p" className={styles.topologyText}> {tooltipData?.device?.deviceId}</Typography>
                        </Box>
                        {tooltipData?.device?.systemDetails?.ip &&
                            <Box className={styles.topologyItem}>
                                <Box display={'flex'} alignItems={'center'} gap={'3px'}>
                                    <IPIcon fill={theme.palette.primary.main} width="13" height="13" />
                                    <Typography className={styles.topologyHeading} variant="p">IP </Typography>
                                </Box>
                                <Typography variant="p" className={styles.topologyText}>{tooltipData?.device?.systemDetails?.ip}</Typography>
                            </Box>
                        }
                    </Box>
                </Tooltip>
            }
        </Box>
    );
};

export default MultiTopology;
