import { Grid } from '@mui/material'
import MyCard from 'components/shared/Card/Card'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { analyticsActions } from '../../../redux/slice/analytics.slice'
import { GET_THREATS } from '../../../redux/types/multi.types'
import GraphTitle from './GraphTitle'
import { anomalyRangeOptions } from './helper'
import ThreatDetectionChart from './ThreatDetectionChart'
import TableSkeleton from 'components/shared/Skeletons/TableSkeleton'
import { useNavigate } from 'react-router-dom'
import { uiActions } from '../../../redux/slice/ui.slice'
import { alertsActions } from '../../../redux/slice/alerts.slice'

const ThreatDetection = () => {

    const dispatch = useDispatch();
    const [selectedRange, setSelectedRange] = useState('1year');
    const [loading, setLoading] = useState(false); // Add a loading state
    const threatsData = useSelector(analyticsActions.selectors.getThreats);

    useEffect(() => {
        const handleChartData = (range) => {
            setLoading(true);
            const payload = {
                range
            }

            dispatch({
                type: GET_THREATS,
                payload,
            });
        }
        handleChartData(selectedRange)
    }, [dispatch, selectedRange]);

    useEffect(() => {
        if (threatsData) {
            setLoading(false);
        }
    }, [threatsData])


    const navigate = useNavigate();
    const handleClickChart = (level) => {
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        switch (level) {
            case 'High Level Threats':
                dispatch(alertsActions.actions.setSelectedLevel("High"))
                break;
            case 'Medium Level Threats':
                dispatch(alertsActions.actions.setSelectedLevel("Medium"))
                break;
            case 'Low Level Threats':
                dispatch(alertsActions.actions.setSelectedLevel("Low"))
                break;
            default:
            //
        }
        navigate("/dashboard");
    }

    return (
        <MyCard height={450}>
            <Grid item xs={12}>
                <GraphTitle
                    setTime={setSelectedRange}
                    time={selectedRange}
                    setSelectedRange={setSelectedRange}
                    showFullScreenBtn={false}
                    title={'Threat Detection Over Time'}
                    menuOptions={anomalyRangeOptions}
                />
                {loading ? ( // Show skeleton when loading
                    <TableSkeleton rows={5} cols={5} />
                ) : (
                    <ThreatDetectionChart threatsData={threatsData} handleClickChart={handleClickChart} />
                )}
            </Grid>
        </MyCard>
    )
}

export default ThreatDetection
